import * as React from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import { Stack, Container, Box } from '@mui/material';
import NavBar from 'app/components/NavBar';
// import Footer from 'app/components/Footer';
import LeftSidebar from 'app/components/LeftSidebar';
import { SearchPage } from '../SearchPage/Loadable';
import { ResultsPage } from '../ResultsPage/Loadable';
import { DonorPage } from '../DonorPage/Loadable';
import { DashboardPage } from '../DashboardPage/Loadable';
import { CartPage } from '../CartPage/Loadable';
import { AnimatePresence } from 'framer-motion';
import { NotFoundPage } from 'app/components/NotFoundPage/Loadable';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveTab } from '../SearchPage/slice/selectors';
import { useSearchSlice } from '../SearchPage/slice';
import { getAuthUser } from 'store/authentication/authentication.selector';
import { singleSearchDataAsync } from '../SearchPage/slice';
import Footer from 'app/components/Footer';
import { PrivacyPage } from '../PrivacyPage/Loadable';
import StripePaymentPage from '../StripePaymentPage';
import SucceededPage from '../SucceededPage';
import MainPage from 'app/components/Prospect-Tracker/pages/main';
import ProspectImporter from 'app/components/Prospect-Importer';
import { MOADBSearch } from 'app/components/MOADBSearch';
import P2PMessageQueue from 'app/components/P2PMessageQueue';
import ProcessMonitor from 'app/components/Process-Monitor';
import SMTPForm from 'app/components/SMTPForm';
import SMTPManager from 'app/components/Prospect-Tracker/components/SMTPManager';
import TemplateManager from 'app/components/Prospect-Tracker/components/TemplateManager';
import Reports from 'app/components/Reports';
import ScraperScript from 'app/components/ScraperScript';
import Settings from 'app/components/Settings';
import AcceptToS from '../AcceptTC';

export function HomePage() {
  const activeTab = useSelector(selectActiveTab);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { actions } = useSearchSlice();
  const location = useLocation();
  const currentUser = useSelector(getAuthUser);

  const handleSetActive = tab => {
    dispatch(actions.setActiveTab(tab));
    const query = {
      query: {},
      sort: [['donationScore', -1]],
      email: currentUser?.email,
      limit: 10,
    };
    switch (tab) {
      case 'Home':
        dispatch(actions.resetSearchSlice(null));
        navigate('/');
        break;
      case 'Browse':
        dispatch(actions.setSearchType('single'));
        dispatch(actions.setRecentSingleSearchQuery({}));
        dispatch(singleSearchDataAsync(query));
        navigate('/search/results');
        break;
      case 'Bulk Search':
        dispatch(actions.setSearchType('bulk'));
        navigate('/search');
        break;
      case 'Favorites':
        break;
      case 'Search History':
        break;
      // default:
      //   return false
    }
  };
  const element = useRoutes([
    {
      path: '/login',
      element: <Navigate to="/" replace />,
    },
    {
      path: '/',
      element: <DashboardPage />,
    },
    {
      path: '/search',
      element: <SearchPage />,
    },
    {
      path: '/search/results',
      element: <ResultsPage />,
    },
    {
      path: '/search/results/:id',
      element: <DonorPage />,
    },
    {
      path: '/cart',
      element: <CartPage />,
    },
    {
      path: '/privacy',
      element: <PrivacyPage />,
    },
    {
      path: '/about',
      element: <h1>About</h1>,
    },
    {
      path: '/faq',
      element: <h1>FAQ</h1>,
    },
    {
      path: '/terms',
      element: <h1>Terms</h1>,
    },
    {
      path: '/privacy',
      element: <h1>Privacy</h1>,
    },
    {
      path: '/contact',
      element: <h1>Contact</h1>,
    },
    {
      path: '/blog',
      element: <h1>Blog</h1>,
    },
    {
      path: '/disclaimers',
      element: <h1>Disclaimers</h1>,
    },
    {
      path: '/checkout',
      element: <StripePaymentPage />,
    },
    {
      path: '/thank-you',
      element: <SucceededPage />,
    },
    {
      path: '/process-monitor',
      element: <ProcessMonitor />,
    },
    {
      path: '/prospect-tracker',
      element: <MainPage />,
    },
    {
      path: '/prospect-importer',
      element: <ProspectImporter />,
    },
    {
      path: '/moadb-search',
      element: <MOADBSearch />,
    },
    {
      path: '/p2p-message-queue',
      element: <P2PMessageQueue />,
    },
    {
      path: '/reports',
      element: <Reports />,
    },
    {
      path: '/scraper-script',
      element: <ScraperScript />,
    },
    {
      path: '/smtp-form',
      element: <SMTPForm />,
    },
    {
      path: '/smtp-manager',
      element: <SMTPManager />,
    },
    {
      path: '/template-manager',
      element: <TemplateManager />,
    },
    {
      path: '/settings',
      element: <Settings />,
    },

    {
      path: '*',
      element: <NotFoundPage />,
    },
  ]);

  if (!element) return null;
  if (!!currentUser?._id && !currentUser?.acceptedToS)
    return (
      <div
        style={{
          display: ['/prospect-tracker'].includes(location.pathname)
            ? 'inline-block'
            : 'initial',
          height: '100vh',
        }}
      >
        <NavBar />
        <AcceptToS user={currentUser} />
      </div>
    );

  return (
    <>
      {[
        '/',
        '/checkout',
        '/moadb-search',
        '/p2p-message-queue',
        '/process-monitor',
        '/prospect-importer',
        '/prospect-tracker',
        '/reports',
        '/scraper-script',
        '/settings',
        '/smtp-form',
        '/smtp-manager',
        '/template-manager',
      ].includes(location.pathname) ? (
        <div
          style={{
            display: ['/prospect-tracker'].includes(location.pathname)
              ? 'inline-block'
              : 'initial',
            height: '100vh',
          }}
        >
          {[
            '/',
            '/moadb-search',
            '/p2p-message-queue',
            '/process-monitor',
            '/prospect-importer',
            '/prospect-tracker',
            '/reports',
            '/scraper-script',
            '/smtp-form',
            '/settings',
            '/smtp-manager',
            '/template-manager',
          ].includes(location.pathname) && <NavBar />}
          {React.cloneElement(element, { key: location.pathname })}
        </div>
      ) : (
        <Stack
          justifyContent="space-between"
          alignItems="center"
          height="100vh"
        >
          <NavBar />
          <Container
            maxWidth="lg"
            sx={{ paddingTop: '48px', paddingBottom: '60px' }}
          >
            <AnimatePresence mode="wait">
              {React.cloneElement(element, { key: location.pathname })}
            </AnimatePresence>
            {/* <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 5.5fr',
                gridColumnGap: '40px',
                alignItems: 'start',
                gridRowGap: '24px',
                gridTemplateRows: 'auto',
              }}
            >
              <LeftSidebar activeTab={activeTab} setActiveTab={handleSetActive} />
              <Box
                sx={{
                  maxWidth: '940px',
                  display: 'block',
                }}
              >
                <AnimatePresence mode="wait">
                  {React.cloneElement(element, { key: location.pathname })}
                </AnimatePresence>
              </Box>
            </Box> */}
          </Container>
          <Footer />
        </Stack>
      )}
    </>
  );
}
