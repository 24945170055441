import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import GoogleLoginButton from './GoogleButton';
import { Link, useSearchParams } from 'react-router-dom';
import { signInUser, signInUserPasswordlessly } from 'api/auth.service';
import { Flip, toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useAuthSliceSlice } from 'store/authentication/authentication';
import FormTextField from 'app/components/Form/FormTextField';
import DRE_logo from 'app/assets/dre_logo_green_blue.png';
import styled from 'styled-components';
import { authFormStyle } from 'app/components/Form/FormTextField/config';
import { useCookies } from 'react-cookie';
import PasswordlessButton from './PasswordlessButton';
import { useMediaQuery } from '@mui/material';

const SignUpLink = styled(Link)`
  color: #27bdb2;
  transition: all 0.3s ease;
  &:hover,
  &:focus {
    color: red;
  }
`;

interface FormData {
  username: string;
  password: string;
}

const initialValues: FormData = {
  username: '',
  password: '',
};

const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [_, setCookie] = useCookies();
  const {
    actions: { usernamePasswordLogin },
  } = useAuthSliceSlice();
  const [token] = useState<any>(searchParams.get('token') ?? null);
  const isMobile = useMediaQuery(`( max-width: 1224px )`);

  const onSubmit = async ({ username, password }: FormData) => {
    signInUser(username, password)
      .then(response => {
        setCookie('user', {
          acceptedToS: response.acceptedToS,
          canUpdateProspects: ((response?.permissions ?? []) as any).includes(
            'canUpdateProspects',
          ),
          email: response.email,
          expiration: response.expiration,
          sessionToken: response.sessionToken,
          verify: response.verify,
        });

        if (response.passwordExpirationTime) {
          window.location.href = `/reset?email=${username}`;
        } else {
          dispatch(usernamePasswordLogin(response));
          window.location.href = '/';
        }
      })
      .catch(error => {
        if (axios.isAxiosError(error)) {
          toast.error(error.response?.data.error);
        }
      });
  };

  async function validate(values: FormData) {
    const { username, password } = values;

    if (!username) {
      return { username: 'Username is required.' };
    }
    if (!password) {
      return { password: 'Password is required.' };
    }
  }

  useEffect(() => {
    if (!!token) {
      signInUserPasswordlessly(token)
        .then(response => {
          if (!!response) {
            dispatch(usernamePasswordLogin(response));
            window.location.href = '/';
          }
        })
        .catch(error => {
          if (axios.isAxiosError(error)) {
            toast.error(error.response?.data.error);
          }
        });
    }
  }, [dispatch, token, usernamePasswordLogin]);

  useEffect(() => {
    if (!isMobile) {
      toast.info(
        <>
          <div className="fs-5 mb-3 border-bottom">Forgot your password?</div>
          <div className="mb-3">
            Click <b>Send Access Link</b>. Have a link sent to your email. Click
            the link to login without a password!
          </div>
          <small>*Check spam and junk folders.</small>
        </>,
        {
          autoClose: 10000,
          position: 'bottom-right',
          theme: 'dark',
          transition: Flip,
        },
      );
    }
  }, [isMobile]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100vh',
        alignItems: 'center',
        background: '#0a3042',
        backgroundImage: 'radial-gradient(circle at 0%, #0a3042 62%, #155d81)',
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.8,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <Container maxWidth="sm">
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ width: 1, height: '85vh' }}
          >
            <Box className="form-container">
              <Box
                sx={{
                  display: 'flex',
                  // alignItems: 'baseline',
                  flexDirection: 'column',
                  // gap: 1,
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignContent: 'center',
                  marginBottom: '40px',
                }}
              >
                <img
                  src={DRE_logo}
                  style={{
                    width: '60px',
                    borderRadius: '13px',
                  }}
                  alt="dre-logo"
                />
                <Typography
                  sx={{
                    fontSize: '28px',
                    fontWeight: 700,
                    lineHeight: '1.571em',
                    color: '#fff',
                    fontFamily: 'Inter, sans-serif',
                    marginBottom: '7px',
                  }}
                >
                  Login to DRE
                </Typography>
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 500,
                    lineHeight: '22px',
                    color: '#828aa3',
                    fontFamily: 'Inter, sans-serif',
                    marginBottom: '16px',
                  }}
                >
                  Log in to access one of the world's most accurate datasets
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 1,
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                <Form
                  initialValues={initialValues}
                  validate={validate}
                  onSubmit={onSubmit}
                  render={({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          // alignItems: 'baseline',
                          flexDirection: 'column',
                          gap: 3,
                          width: '100%',
                        }}
                      >
                        <Field name="username" type="email">
                          {props => (
                            <FormTextField
                              {...props}
                              placeholder="Email"
                              sx={authFormStyle}
                            />
                          )}
                        </Field>
                        <Field name="password" type="password">
                          {props => (
                            <FormTextField
                              {...props}
                              placeholder="Password"
                              sx={authFormStyle}
                            />
                          )}
                        </Field>
                        <Button
                          sx={{
                            textTransform: 'none',
                            width: '100%',
                            backgroundImage:
                              'linear-gradient(#36e961, #64ee85)',
                            border: '1px solid #36e961',
                            borderRadius: '5px',
                            color: '#fff',
                            transition: 'all 0.3s',
                            '&:hover': {
                              transform: 'scale(1.02)',
                            },
                          }}
                          type="submit"
                          variant="contained"
                          disabled={submitting}
                        >
                          Log-in
                        </Button>

                        <PasswordlessButton />
                      </Box>
                    </form>
                  )}
                />

                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                    color: '#828aa3',
                    fontFamily: 'Inter, sans-serif',
                  }}
                >
                  Don't have an account?&nbsp;
                  <SignUpLink to="/signup">Sign up</SignUpLink>
                </Typography>
                <Box width={'240px'} marginTop={'10px'}>
                  <GoogleLoginButton behaviorType="SignIn" />
                </Box>
              </Box>
            </Box>
          </Stack>
        </Container>
      </motion.div>
    </Box>
  );
};

export default LoginPage;
